<script>
import { toDinheiro, toTelefone, formatLocaleDate } from '@/util/Formater.js'
import OrderDetail from './OrderDetail.vue'
import Erro from '@/components/shared/Erro.vue'
import {
  addMonths,
  format,
  formatISO,
  endOfDay,
  parseISO,
  startOfDay,
  startOfMonth
} from 'date-fns'
import { ORDER_STATUS_LIST } from '@/util/constants'

export default {
  name: 'OrdersTable',
  components: {
    OrderDetail: OrderDetail,
    'erro-msg': Erro
  },
  data() {
    return {
      erros: [],
      tipoEntrega: null,
      pesquisa: null,
      showDetalhePedido: false,
      pedido: null,
      filteredOrders: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      dates: [
        format(new Date(), 'yyyy-MM-dd'),
        format(new Date(), 'yyyy-MM-dd')
      ],
      statusList: ORDER_STATUS_LIST,
      statusFilter: [],
      menu: false,
      showDialogCancelaPedido: false,
      showDialogConfirmaCancelaPedido: false,
      showDialogPedidoEntregue: false,
      showDialogPedidoEnviado: false,
      estabelecimentoTemCadastroPendente: false,
      orderTableFields: [
        {
          value: 'id',
          text: 'Pedido',
          align: 'center',
          sortable: false,
          width: 30
        },
        {
          value: 'created_at',
          text: 'Data',
          align: 'center',
          sortable: false,
          width: 80
        },
        { value: 'qtd', text: 'Qtd.', align: 'center', sortable: false },
        {
          value: 'itens',
          text: 'Sacola',
          align: 'stamapGettersrt',
          sortable: false
        },
        {
          value: 'cliente.nome',
          text: 'Cliente',
          align: 'center',
          sortable: false
        },
        {
          value: 'total_pago',
          text: 'Valor',
          align: 'center',
          sortable: false
        },
        { value: 'status', text: 'Status', align: 'center', sortable: false }
      ],
      loading: false,
      loadingConfirmationButton: false,
      responsavel: null,
      motivoCancelamento: null,
      observacao: null,
      numTicket: null,
      devolverSacola: false,
      searchTimeout: null,
      countOrder: null,
      reloadIntervalId: null,
      screenSize: this.$vuetify.breakpoint.name,
      cancellationReasonsToShow: [
        'ITEMS_EXPIRED',
        'PRODUCT_UNAVAILABLE',
        'ORDER_DOES_NOT_MATCH_THE_VALUE'
      ]
    }
  },
  props: {
    estabelecimento: {},
    enabledStatusToFilter: {
      require: false,
      type: Array,
      default: () => {
        return []
      }
    },
    startRangeDateToFilter: {
      require: false,
      type: String,
      default: () => {
        return format(startOfMonth(addMonths(new Date(), -1)), 'yyyy-MM-dd')
      }
    },
    endRangeDateToFilter: {
      require: false,
      type: Date,
      default: () => {
        return new Date()
      }
    },
    exportOrder: {
      require: false,
      type: Boolean,
      default: false
    },
    analyticsEventLabels: {
      require: false,
      type: Object,
      default: () => {
        return {
          ORDER_CANCELED: 'order_canceled',
          OPEN_ORDER_DETAILS: 'order_details',
          ORDER_SEARCH: 'order_search',
          ORDER_DATE_FILTER: 'order_date_filter',
          ORDER_STATUS_FILTER: 'order_status_filter',
          ORDER_DOWNLOAD: 'order_download'
        }
      }
    },
    initialStatusFilter: {
      require: false,
      type: String,
      default: ``
    }
  },
  methods: {
    addMonths,
    toDinheiro,
    toTelefone,
    formatLocaleDate,
    parseISO,
    format,
    startOfMonth,
    removeFocusOnClear() {
      this.$refs.select.blur()
    },
    sendAnalyticsEvent(label, data = null) {
      if (label) {
        if (data) this.$gtag.event(label, data)
      } else {
        this.$gtag.event(label)
      }
    },
    openDetalhePedido: function (row) {
      if (row.status === 'CANCELADO') {
        this.sendAnalyticsEvent(this.analyticsEventLabels.ORDER_CANCELED, {
          order: row
        })
      }

      this.pedido = row
      this.showDetalhePedido = true

      this.sendAnalyticsEvent(this.analyticsEventLabels.OPEN_ORDER_DETAILS, {
        order: row
      })
    },
    changeSearch() {
      this.pagination.first = 0

      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.filterOrders, 1000)

      this.sendAnalyticsEvent(this.analyticsEventLabels.ORDER_SEARCH)
    },
    changeDateSearch(dates) {
      this.$refs.menu.save(dates)
      this.sendAnalyticsEvent(this.analyticsEventLabels.ORDER_DATE_FILTER, {
        dates
      })
      this.changeSearch()
    },
    changeStatusSearch(status) {
      this.sendAnalyticsEvent(this.analyticsEventLabels.ORDER_STATUS_FILTER, {
        status
      })
      this.changeSearch()
    },
    next(page) {
      this.pagination.page = page
      this.pagination.first = (page - 1) * this.pagination.limit
      this.filterOrders()
    },
    filterOrders() {
      const formattedFirstDate = this.dates[0].replace(/-/g, '\/')
      const formattedSecondDate = this.dates[1]?.replace(/-/g, '\/')

      const startDate = startOfDay(new Date(formattedFirstDate))
      const endDate = formattedSecondDate
        ? endOfDay(new Date(formattedSecondDate))
        : endOfDay(new Date(formattedFirstDate))

      var result = this.getOrderSearch(
        this.pesquisa,
        this.pagination.first,
        this.pagination.limit,
        startDate,
        endDate,
        this.statusFilter
      )
      return result.then((response) => {
        if (response) {
          this.filteredOrders = response.data
          this.pagination.total = response.count

          if (this.pagination.total <= this.pagination.limit) {
            this.pagination.page = 1
          }
          return response
        }
      })
    },
    getOrderSearch(
      searchFilter = '',
      first = 0,
      limit = this.pagination.limit,
      startDate = startOfDay(new Date()),
      endDate = endOfDay(new Date()),
      statusFilter = null
    ) {
      this.erros = []
      this.loading = true

      if (!searchFilter) {
        searchFilter = ''
      }

      let getOrdersUrl = `/v1/partners/${
        this.estabelecimento.id
      }/orders?search=${searchFilter}&first=${first}&limit=${limit}&startDate=${formatISO(
        startDate
      )}&endDate=${formatISO(endDate)}`

      if (this.statusFilter?.length > 0) {
        getOrdersUrl += `&status=${this.statusFilter}`
      } else {
        getOrdersUrl += `&statuses=${this.initialStatusFilter}`
      }

      return this.$http
        .get(getOrdersUrl)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDialogPedidoEntregue(pedidoId) {
      this.pedidoId = pedidoId
      this.showDialogPedidoEntregue = true
    },
    openDialogPedidoEnviado(pedidoId) {
      this.pedidoId = pedidoId
      this.showDialogPedidoEnviado = true
    },
    pedidoEntregue() {
      this.loadingConfirmationButton = true
      this.erros = []
      this.$http
        .put(
          `/v1/parceiro/${this.estabelecimento.id}/pedido/${this.pedidoId}/entregue`
        )
        .then((r) => {
          const changedOrder = r.data
          const changedOrderFromList = this.filteredOrders.find((o) => {
            return o.uuid == changedOrder.uuid
          })
          changedOrderFromList.status = changedOrder.status
          changedOrderFromList.status_new = changedOrder.status_new

          this.closeDialogConfirm()
        })
        .catch((error) => {
          this.erros.push(
            error.response?.data ??
              'Houve um erro inesperado. Por favor, contate nosso suporte'
          )
        })
        .finally(() => {
          this.loadingConfirmationButton = false
        })
    },
    pedidoEnviado() {
      this.loadingConfirmationButton = true
      this.erros = []
      this.$http
        .put(
          `/v1/parceiro/${this.estabelecimento.id}/pedido/${this.pedidoId}/enviado`
        )
        .then(() => {
          this.closeDialogConfirm()
          this.filterOrders()
        })
        .catch((error) => {
          this.erros.push(
            error.response?.data ??
              'Houve um erro inesperado. Por favor, contate nosso suporte'
          )
        })
        .finally(() => {
          this.loadingConfirmationButton = false
        })
    },
    openDialogCancelaPedido(item) {
      this.pedidoId = item.uuid
      this.tipoEntrega = item.tipo_entrega
      this.showDialogCancelaPedido = true
    },
    openDialogConfirmaCancelaPedido() {
      this.showDialogConfirmaCancelaPedido = true
    },
    cancelaPedido() {
      this.erros = []
      this.loadingConfirmationButton = true

      if (!this.motivoCancelamento) {
        this.erros.push(
          'Informe o motivo do cancelamento e, se possível, justifique por favor!'
        )
      }

      if (this.erros.length) {
        this.loadingConfirmationButton = false
        return
      }

      const data = {
        responsible_name: this.responsavel,
        cancellation_reason: this.motivoCancelamento,
        responsible_observation: this.observacao
      }

      this.$http
        .post(
          `/v1/partners/${this.estabelecimento.id}/orders/${this.pedidoId}/cancellation`,
          data
        )
        .then((r) => {
          const changedOrder = r.data
          const changedOrderFromList = this.filteredOrders.find((o) => {
            return o.uuid == changedOrder.uuid
          })
          changedOrderFromList.status = changedOrder.status
          changedOrderFromList.status_new = changedOrder.status_new
          changedOrderFromList.order_cancellation =
            changedOrder.order_cancellation
          this.closeDialogConfirm()
        })
        .catch((error) => {
          this.erros.push(
            error.response?.data ??
              'Houve um erro inesperado. Por favor, contate nosso suporte'
          )
        })
        .finally(() => {
          this.loadingConfirmationButton = false
        })
    },
    closeDialogConfirm() {
      this.erros = []
      this.showDialogCancelaPedido = false
      this.showDialogPedidoEntregue = false
      this.showDialogPedidoEnviado = false
      this.showDialogConfirmaCancelaPedido = false
    },
    async autoReload() {
      var newOrders = this.getOrderSearch()
      newOrders.then((response) => {
        if (response) {
          this.resetFilters()
          if (!this.pesquisa) {
            this.filteredOrders = response.data
            this.pagination.total = response.count
            this.pagination.page = 1
          }
          let newOrderCount = 0
          if (response.count > 0) {
            newOrderCount = response.count
            if (this.countOrder === null || this.countOrder === undefined) {
              this.countOrder = newOrderCount
            }
          }
        }
      })
      this.$emit('getGondolasEvent')
    },
    resetFilters() {
      this.dates = [
        format(new Date(), 'yyyy-MM-dd'),
        format(new Date(), 'yyyy-MM-dd')
      ]
      this.statusFilter = []
    },
    renderPickupCode(order) {
      if (order.order_integrations?.length > 0) {
        return order.order_integrations[0]?.pickup_code
      }
      return null
    },
    renderReturnCode(order) {
      if (order.order_integrations?.length > 0) {
        return order.order_integrations[0]?.return_code
      }
      return null
    },
    exportOrdersCsv() {
      if (!this.pesquisa || this.pesquisa == 'null') {
        this.pesquisa = ''
      }
      const formattedFirstDate = this.dates[0].replace(/-/g, '\/')
      const formattedSecondDate = this.dates[1]?.replace(/-/g, '\/')

      const startDate = startOfDay(new Date(formattedFirstDate))
      const endDate = formattedSecondDate
        ? endOfDay(new Date(formattedSecondDate))
        : endOfDay(new Date(formattedFirstDate))

      let exportOrdersUrl = `/v1/partners/${
        this.estabelecimento.id
      }/orders/export?search=${this.pesquisa}&startDate=${formatISO(
        startDate
      )}&endDate=${formatISO(endDate)}`

      if (this.statusFilter) {
        exportOrdersUrl += `&status=${this.statusFilter}`
      } else {
        exportOrdersUrl += `&statuses=${this.initialStatusFilter}`
      }

      this.sendAnalyticsEvent(this.analyticsEventLabels.ORDER_DOWNLOAD, {
        status: this.statusFilter,
        startDate: formatISO(startDate),
        endDate: formatISO(endDate)
      })

      this.$http
        .get(exportOrdersUrl, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `relatorio_pedidos_${this.dates[0]}_${
              this.dates[1] || this.dates[0]
            }.csv`
          )
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch((error) => {
          this.erros.push(error.message)
        })
    }
  },
  computed: {
    items() {
      return this.filteredOrders
    },
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit)
    },
    dateRangeText() {
      return this.dates
        .map((date) => {
          return format(parseISO(date), 'dd/MM/yyyy')
        })
        .join(' ~ ')
    },
    searchInputLayoutSettings() {
      return { cols: 12, sm: 4, md: 3, lg: 4, xl: 6 }
    },
    statusInputLayoutSettings() {
      return { cols: 12, sm: 3, md: 3, lg: 3, xl: 2 }
    },
    dateInputLayoutSettings() {
      return { cols: 12, sm: 5, md: 6, lg: 5, xl: 4 }
    }
  },
  created() {
    this.filterOrders().then((response) => {
      let orders = response?.data
      this.countOrder = response?.count
      if (orders?.length > 0) {
        if (!this.lastOrderId) {
          this.lastOrderId = orders[0].id
        }
      }

      this.autoReload()
    })
  },
  mounted() {
    const autoReloadEvery60Seconds = 1 * 60 * 1000
    this.reloadIntervalId = setInterval(
      this.autoReload,
      autoReloadEvery60Seconds
    )

    this.responsavel = this.$store.getters.usuarioLogado.email
    this.estabelecimentoTemCadastroPendente =
      this.$store.getters.estabelecimentoTemCadastroPendente ?? false

    if (this.enabledStatusToFilter?.length > 0) {
      this.statusList = this.enabledStatusToFilter
    }
  },

  destroyed() {
    clearTimeout(this.reloadIntervalId)
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col v-bind="searchInputLayoutSettings">
        <v-text-field
          v-model="pesquisa"
          prepend-icon="mdi-magnify"
          label="Pesquisa"
          class="search-input"
          :class="{
            'mb-4 ': screenSize != 'xs'
          }"
          clearable
          @input="changeSearch"
          :disabled="estabelecimentoTemCadastroPendente ?? false"
        />
      </v-col>
      <v-col
        v-bind="statusInputLayoutSettings"
        :class="{
          'mb-4 ': screenSize != 'xs'
        }"
      >
        <v-select
          ref="select"
          v-model="statusFilter"
          :items="statusList"
          item-text="label"
          item-value="key"
          color="#8B8B8B"
          solo
          flat
          clearable
          hide-details
          @click:clear="removeFocusOnClear"
          @change="changeStatusSearch"
          label="Status"
          class="status-filter"
          :disabled="estabelecimentoTemCadastroPendente ?? false"
        ></v-select>
      </v-col>
      <v-col class="d-flex mb-3" v-bind="dateInputLayoutSettings">
        <div style="flex: 1" class="mr-3">
          <v-menu
            style="color: black"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
            nudge-left="24px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="filter-card" v-bind="attrs" v-on="on">
                <div class="container">
                  <v-icon>mdi-calendar</v-icon>
                </div>
                <div
                  style="
                    align-self: center;
                    grid-area: content;
                    overflow: hidden;
                  "
                >
                  <div><span class="title-text">Data</span></div>
                  <div>
                    <span class="subtitle-text">{{ dateRangeText }}</span>
                  </div>
                </div>
              </div>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              :disabled="estabelecimentoTemCadastroPendente ?? false"
              range
              color="#FF6600"
              :min="startRangeDateToFilter"
              :max="format(new Date(), 'yyyy-MM-dd')"
              locale="pt-br"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="changeDateSearch(dates)"
                :disabled="estabelecimentoTemCadastroPendente ?? false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <button
          v-if="exportOrder"
          @click="exportOrdersCsv"
          style="width: 50px; height: 56px"
          class="export-button"
        >
          <v-icon color="#ffff">mdi-download-box-outline </v-icon>
        </button>
      </v-col>
    </v-row>

    <v-card
      style="border-radius: 20px"
      :class="{
        'mt-6 ': screenSize == 'xs'
      }"
    >
      <v-data-table
        id="order-table-id"
        ref="order-table"
        primary-key="id"
        stacked="md"
        class="orders-table"
        hover
        show-empty
        responsive
        hide-default-footer
        :loading="loading"
        loading-text="Carregando dados..."
        no-data-text="Não há pedidos para os filtros especificados"
        :page.sync="pagination.page"
        :items="items"
        :headers="orderTableFields"
        @click:row="openDetalhePedido"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-col cols="12">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.status == 'CANCELADO'"
                  class="pa-0"
                >
                  <span
                    class="d-flex justify-space-between align-center status waiting-payment"
                    v-if="item.status == 'WAITING_PAYMENT'"
                    >{{ item.status == 'WAITING_PAYMENT' && 'AG. PAGAMENTO' }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </span>
                  <span
                    class="d-flex justify-space-between align-center status retirar"
                    v-if="item.status == 'RETIRAR'"
                    >{{ item.status }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </span>
                  <span
                    class="d-flex justify-space-between align-center status delivery"
                    v-if="item.status == 'DELIVERY'"
                    >{{ item.status }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </span>
                  <span
                    class="d-flex justify-space-between align-center status enviado"
                    v-if="item.status == 'ENVIADO'"
                    >{{ item.status }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </span>
                  <span
                    class="d-flex justify-space-between align-center status"
                    v-if="item.status == 'ENTREGUE'"
                    >{{ item.status }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </span>
                  <span
                    class="d-flex justify-space-between align-center status cancelar"
                    v-if="item.status == 'CANCELADO'"
                    >{{ item.status }}
                  </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  class="txt_btn"
                  @click="openDialogPedidoEnviado(item.uuid)"
                  v-if="item.status === 'DELIVERY'"
                  >ENVIADO</v-list-item
                >
                <v-list-item
                  class="txt_btn"
                  @click="openDialogPedidoEntregue(item.uuid)"
                  v-if="item.status === 'RETIRAR'"
                  >ENTREGUE</v-list-item
                >
                <v-list-item
                  class="txt_btn"
                  @click="openDialogCancelaPedido(item)"
                  v-if="item.status !== 'CANCELADO'"
                  >CANCELADO</v-list-item
                >
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" class="mt-0 mb-0 pt-0 delivery-codes">
            <div
              v-if="item.status === 'DELIVERY' && renderPickupCode(item)"
              class="container-delivery-validation-code mb-1"
            >
              <span class="delivery-validation-code">
                cód. coleta:
                <span class="font-weight-bold"
                  >{{ renderPickupCode(item) }}
                </span>
              </span>
              <v-tooltip
                bottom
                content-class="delivery-validation-code-tooltip"
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ml-1"
                    color="#615F61"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  este código deve ser informado ao motoboy que irá coletar o
                  pedido
                </span>
              </v-tooltip>
            </div>
            <div
              v-if="renderReturnCode(item)"
              class="container-return-validation-code"
            >
              <span class="delivery-return-code">
                cód. retorno:
                <span class="font-weight-bold"
                  >{{ renderReturnCode(item) }}
                </span>
              </span>
              <v-tooltip
                bottom
                content-class="delivery-return-code-tooltip"
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ml-1"
                    color="#615F61"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  caso o motoboy precise retornar com este pedido, você deve
                  informar esse código para receber o pedido
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <p style="font-weight: bold; font-size: 1.6em">{{ item.id }}</p>
          <p
            v-if="
              item.status !== 'CANCELADO' ||
              (item.status === 'CANCELADO' &&
                cancellationReasonsToShow.includes(
                  item.order_cancellation?.cancellation_reason
                ))
            "
          >
            <v-rating
              v-if="item.avaliacao"
              background-color="#FF6600"
              color="#FF6600"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half"
              length="5"
              readonly
              size="14"
              :value="item.avaliacao"
            ></v-rating>
          </p>
        </template>
        <template v-slot:[`item.created_at`]="{ item }"
          >{{ formatLocaleDate(item.created_at) }}
        </template>
        <template v-slot:[`item.total_pago`]="{ item }">
          <p class="text-lg-body-2">
            {{ toDinheiro(item.total_pago ? item.total_pago : 0.0) }}
          </p>
        </template>
        <template v-slot:[`item.qtd`]="{ item }">
          <p style="font-weight: bold; font-size: 1.6em">
            {{
              item.itens.reduce((acc, i) => {
                return i.quantidade + acc
              }, 0)
            }}
          </p>
        </template>
        <template v-slot:[`item.itens`]="{ item }">
          <ul class="pa-0 text-lg-body-2">
            <li
              v-for="obj in item.itens"
              :key="obj.cesta.id + '_' + obj.id + '_' + obj.cesta.category"
            >
              <strong>{{ obj.quantidade }}</strong
              >x
              {{
                obj.cesta.category && obj.cesta.category !== ''
                  ? obj.cesta.category + ' - '
                  : ''
              }}{{ obj.cesta.tipo }}<br />
              <sup class="text-decoration-line-through">
                {{ toDinheiro(obj.cesta.valor_referencia) }}
              </sup>
              {{ toDinheiro(obj.cesta.valor) }}
            </li>
          </ul>
        </template>
        <template v-slot:[`item.cliente.nome`]="{ item }">
          <p class="text-lg-body-2">{{ item.cliente.nome }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-pagination
      circle
      v-model="pagination.page"
      :length="totalPaginas"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      @input="next"
    />

    <v-dialog v-model="showDetalhePedido" max-width="50rem">
      <order-detail
        v-if="this.pedido"
        :key="this.pedido.uuid"
        :orderId="this.pedido.uuid"
        :partnerId="this.pedido.estabelecimento.id"
      />
    </v-dialog>

    <v-dialog max-width="25rem" persistent v-model="showDialogCancelaPedido">
      <div class="dialog-container">
        <span class="text-subtitle-1 d-flex justify-center"
          >Você realmente deseja cancelar este pedidos?
        </span>
        <v-row style="margin-top: 4px">
          <v-col>
            <v-btn
              class="white--text"
              color="#f74e27"
              @click="closeDialogConfirm"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="white--text"
              color="green"
              @click="openDialogConfirmaCancelaPedido"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        <erro-msg class="mt-3" :erros="erros"></erro-msg>
      </div>
    </v-dialog>

    <v-dialog
      max-width="35rem"
      persistent
      v-model="showDialogConfirmaCancelaPedido"
    >
      <div class="dialog-container">
        <span class="sections-title text-subtitle-1 d-flex justify-center"
          ><strong>Qual o motivo do cancelamento?</strong>
        </span>
        <v-row>
          <v-col>
            <v-row>
              <p class="text-md-body-1 mb-0 pb-0">Motivo</p>
            </v-row>
            <v-row>
              <input
                class="input"
                type="hidden"
                v-model="responsavel"
                disabled
              />
              <v-chip-group
                column
                active-class="primary--text"
                v-model="motivoCancelamento"
              >
                <v-chip outlined filter value="PRODUCT_UNAVAILABLE"
                  >Falta de produto e/ou excedente vendido</v-chip
                >
                <v-chip
                  outlined
                  filter
                  value="ORDER_NOT_PICKED_UP"
                  v-if="tipoEntrega == 'DELIVERY'"
                  >Entregador não coletou o pedido</v-chip
                >
                <v-chip outlined filter value="ORDER_RETURNED"
                  >Pedido devolvido</v-chip
                >
              </v-chip-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <p class="text-md-body-1 mb-0 pb-0 mt-2">Observação</p>
            </v-row>
            <v-row>
              <p class="text-caption mb-0 pb-0 mt-0">
                Por favor, considere preencher para melhorarmos nossos serviços.
              </p>
            </v-row>
            <v-row>
              <textarea class="textarea-input" v-model="observacao"></textarea>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="white--text"
              color="#f74e27"
              :disabled="loadingConfirmationButton"
              @click="closeDialogConfirm"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="white--text"
              color="green"
              :loading="loadingConfirmationButton"
              @click="cancelaPedido"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        <erro-msg class="mt-3" :erros="erros"></erro-msg>
      </div>
    </v-dialog>

    <v-dialog max-width="25rem" persistent v-model="showDialogPedidoEntregue">
      <div class="dialog-container">
        <span class="text-subtitle-1"
          >Você realmente deseja marcar esse pedido como entregue?</span
        >
        <v-row style="margin-top: 4px">
          <v-col>
            <v-btn
              class="white--text"
              color="#f74e27"
              :disabled="loadingConfirmationButton"
              @click="closeDialogConfirm"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="white--text"
              color="green"
              :loading="loadingConfirmationButton"
              @click="pedidoEntregue"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        <erro-msg class="mt-3" :erros="erros"></erro-msg>
      </div>
    </v-dialog>

    <v-dialog max-width="25rem" persistent v-model="showDialogPedidoEnviado">
      <div class="dialog-container">
        <span class="text-subtitle-1"
          >Você realmente deseja marcar esse pedido como enviado?</span
        >
        <v-row style="margin-top: 4px">
          <v-col>
            <v-btn
              class="white--text"
              color="#f74e27"
              :disabled="loadingConfirmationButton"
              @click="closeDialogConfirm"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="white--text"
              color="green"
              :loading="loadingConfirmationButton"
              @click="pedidoEnviado"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        <erro-msg class="mt-3" :erros="erros"></erro-msg>
      </div>
    </v-dialog>
  </div>
</template>

<style scoped>
@import './OrdersTable.scss';
/* Para não quebrar v-date-picker no mobile */
[role='menu'] {
  min-width: 282px !important;
}
</style>
