<template>
  <span class="topsaver d-flex align-center">
    <v-icon class="mr-1">mdi-diamond-stone</v-icon>
    TOPSAVER
  </span>
</template>

<script>
export default {}
</script>
<style scoped>
.topsaver {
  color: #005589;
  font-weight: 700;
  padding: 6px;
  border-radius: 4px;
  background-color: #daf1ff;
}

.v-icon {
  transform: rotate(-10deg);
}
</style>
