<template>
  <div class="star-rating">
    <span
      v-for="index in 5"
      :key="index"
      class="star"
      :class="{ filled: index <= rating }"
      >★</span
    >
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0 && value <= 5
    }
  },
  methods: {}
}
</script>

<style scoped>
.star-rating {
  font-size: 36px;
  cursor: pointer;
}
.star {
  color: #ddd;
}
.star.filled {
  color: #ffd055;
}
</style>
