export function toDinheiro(value) {
  if (!value) return value

  let formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(value)
}

export function toMonetaryWithoutCents(valor) {
  let formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 0
  })

  return formatter.format(valor)
}

export function isEmailValido(email) {
  var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  if (reg.test(email)) {
    return true
  }
  return false
}

// mais de 8 caracteres
// com letras e numero
export function isSenhaForte(senha) {
  let regex = /^(?=.*[A-Za-z])(?=.*\d)[!@#$&*A-Za-z\d]{8,30}$/
  return regex.test(senha)
}

export function toZipCode(value) {
  if (!value) return value

  var v = value.replace(/\D/g, '')
  v = v.replace(/^(\d{5})(\d)/, '$1-$2')
  return v.substring(0, 9)
}

export function toTelefone(value) {
  let v = value.trim();

  if (!v) return v;

  const parts = v.split(',');

  v = parts[0].substring(0, 15); // Limit to 15 characters
  v = v.replace(/\D/g, ''); // Remove non-digit characters

  if (v.length === 12 || v.length === 13) {
    v = v.replace(/^(\d{1,2})(\d{2})(\d{4,5})(\d{4})/g, '+$1 ($2) $3-$4');
  } else {
    v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
    v = v.replace(/(\d)(\d{4})$/, '$1-$2');
  }

  // Handle the part after the comma (if it exists)
  if (parts.length > 1) {
    v += `,${parts[1]}`;
  }

  return v;
}

export function formatLocaleDate(dateString) {
  const dateObject = new Date(dateString)
  const formattedDate = dateObject.toLocaleDateString('pt-BR')
  const time = dateObject.toLocaleTimeString('pt-BR')
  return formattedDate + ' ' + time
}

export function toInitCap(txt) {
  if (!txt) {
    return txt
  }
  return txt.replace(/^./, txt[0].toUpperCase())
}

export function tipoToString(obj) {
  switch (obj) {
    case 'DOCE':
      return 'Doce'
    case 'SALGADO':
      return 'Salgada'
    default:
      return 'Mista'
  }
}

export function isCartaoValido(numero) {
  let number = numero.replace(/ /g, '')
  return number.length >= 15
}

export function toCpf(value) {
  var v = value.replace(/\D/g, '')
  v = v.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  return v.substring(0, 14)
}

export function toCnpj(value) {
  var v = value.replace(/\D/g, '')
  v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  return v.substring(0, 18)
}

export function evenRound(num, decimalPlaces) {
  var d = decimalPlaces || 0
  var m = Math.pow(10, d)
  var n = +(d ? num * m : num).toFixed(8) // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i
  var e = 1e-8 // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n)
  return d ? r / m : r
}
