<template>
  <PageContent>
    <v-container fluid class="flex-grow-1 content">
      <div class="d-flex align-center">
        <p class="sections-title">Avaliações</p>
        <v-tooltip right offset-overflow transition="scroll-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2 mt-1"
              color="#615F61"
              v-bind="attrs"
              v-on="on"
              style="cursor: pointer"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span>
            As notas de 1 a 5 são calculadas com base nos pedidos
            <br />avaliados nos últimos 90 dias. Cada nota representa a
            <br />média das avaliações dos pedidos no período, sendo o
            <br />total de pontos divididos pela quantidade de pedidos.
          </span>
        </v-tooltip>
      </div>
      <v-row class="card-evaluation-measures">
        <v-col xl="6" md="6" sm="12" lg="6" xs="12">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b class="text-header">Nota média</b>
              <p class="ma-0 text-description">últimos 90 dias</p>
            </div>
            <div class="d-flex flex-column align-center">
              <TopSaver
                v-if="partnerStatsEvaluation.top_saver"
                class="topsaver"
              ></TopSaver>
              <div
                v-if="partnerStatsEvaluation.average_evaluation > 0"
                class="d-flex align-center flex-column"
              >
                <b class="text-header-2">
                  {{ partnerStatsEvaluation.average_evaluation }}/5</b
                >
                <StarsTag
                  :rating="partnerStatsEvaluation.average_evaluation"
                ></StarsTag>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col xl="6" md="6" sm="12" lg="6" xs="12">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div class="orders-count">
              <b class="text-header">Pedidos avaliados</b>
              <p class="ma-0 text-description">últimos 90 dias</p>
            </div>
            <div class="d-flex flex-column align-center">
              <b class="text-header-2">
                {{ partnerStatsEvaluation.count_evaluation }}</b
              >
              <p class="text-description">respondentes</p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div class="d-flex align-center">
        <p class="sections-title">Conteúdo da sacola</p>
        <v-tooltip right offset-overflow transition="scroll-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2 mt-1"
              color="#615F61"
              v-bind="attrs"
              v-on="on"
              style="cursor: pointer"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span>
            O conteúdo da Sacola é avaliado por três critérios<br />
            principais: qualidade, quantidade e variedade. Essas<br />
            avaliações te ajudam a identificar pontos de melhoria e
            <br />garantir a satisfação dos consumidores.
          </span>
        </v-tooltip>
      </div>
      <v-row class="card-evaluation-measures">
        <v-col xl="4" md="12" sm="12" lg="4">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b class="text-header">quantidade</b>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_score_quantity > 0"
              class="d-flex flex-column align-center"
            >
              <b class="text-header-2">
                {{ partnerStatsEvaluation.average_score_quantity }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_score_quantity"
              ></StarsTag>
            </div>
            <div v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
        <v-col xl="4" md="12" sm="12" lg="4">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b class="text-header">qualidade</b>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_score_food_quality > 0"
              class="d-flex flex-column align-center"
            >
              <b class="text-header-2">
                {{ partnerStatsEvaluation.average_score_food_quality }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_score_food_quality"
              ></StarsTag>
            </div>
            <div v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
        <v-col xl="4" md="12" sm="12" lg="4">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b class="text-header">variedade</b>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_score_variety > 0"
              class="d-flex flex-column align-center"
            >
              <b class="text-header-2">
                {{ partnerStatsEvaluation.average_score_variety }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_score_variety"
              ></StarsTag>
            </div>
            <div v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div class="d-flex align-center">
        <p class="sections-title">Avaliação geral</p>
        <v-tooltip right offset-overflow transition="scroll-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-2 mt-1"
              color="#615F61"
              v-bind="attrs"
              v-on="on"
              style="cursor: pointer"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span>
            A experiência da compra é avaliado pelos critérios de:<br />
            economia, pedido, embalagem,qualidade e experiencia na <br />
            retirada. Essas avaliações te ajudam a identificar pontos de <br />
            melhoria e garantir a satisfação dos consumidores.
          </span>
        </v-tooltip>
      </div>
      <v-row>
        <v-col xl="12" md="12" sm="12" lg="12" xs="12">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b>economia</b>
              <p class="ma-0">{{ partnerStatsEvaluation.tag_economy_text }}</p>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_tag_economy > 0"
              class="d-flex align-center"
            >
              <b class="mr-3 text-header">
                {{ partnerStatsEvaluation.average_tag_economy }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_tag_economy"
              ></StarsTag>
            </div>
            <div class="w-100" v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
        <v-col xl="12" md="12" sm="12" lg="12" xs="12">
          <v-card
            class="d-flex no-wrap flex-shrink-0 justify-space-between align-center evaluation-card"
          >
            <div>
              <b>pedido</b>
              <p class="ma-0">{{ partnerStatsEvaluation.tag_order_text }}</p>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_tag_order > 0"
              class="d-flex align-center"
            >
              <b class="mr-3 text-header">
                {{ partnerStatsEvaluation.average_tag_order }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_tag_order"
              ></StarsTag>
            </div>
            <div class="flex-shrink-0" v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
        <v-col xl="12" md="12" sm="12" lg="12" xs="12">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b>embalagem</b>
              <p class="ma-0">
                {{ partnerStatsEvaluation.tag_kraft_bag_text }}
              </p>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_tag_kraft_bag > 0"
              class="d-flex align-center"
            >
              <b class="mr-3 text-header">
                {{ partnerStatsEvaluation.average_tag_kraft_bag }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_tag_kraft_bag"
              ></StarsTag>
            </div>
            <div v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
        <v-col xl="12" md="12" sm="12" lg="12" xs="12">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b>qualidade</b>
              <p class="ma-0">
                {{ partnerStatsEvaluation.tag_food_quality_text }}
              </p>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_tag_food_quality > 0"
              class="d-flex align-center"
            >
              <b class="mr-3 text-header">
                {{ partnerStatsEvaluation.average_tag_food_quality }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_tag_food_quality"
              ></StarsTag>
            </div>
            <div v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
        <v-col xl="12" md="12" sm="12" lg="12" xs="12">
          <v-card
            class="d-flex justify-space-between align-center evaluation-card"
          >
            <div>
              <b>retirada (para pedidos retirada)</b>
              <p class="ma-0">
                {{ partnerStatsEvaluation.tag_takeout_text }}
              </p>
            </div>
            <div
              v-if="partnerStatsEvaluation.average_score_takeout"
              class="d-flex align-center"
            >
              <b class="mr-3 text-header">
                {{ partnerStatsEvaluation.average_score_takeout }}/5</b
              >
              <StarsTag
                :rating="partnerStatsEvaluation.average_score_takeout"
              ></StarsTag>
            </div>
            <div v-else>
              <b class="no-data-evaluation">Sem dados para exibir no momento</b>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </PageContent>
</template>

<script>
import { mapState } from 'vuex'
import PageContent from '@/components/shared/PageContent.vue'
import StarsTag from '@/components/shared/StarsTag.vue'
import TopSaver from '@/components/shared/TopSaver.vue'
import { fetchPartnerStatsEvaluation } from '@/api/partnerStats.js'

export default {
  components: {
    PageContent,
    StarsTag,
    TopSaver
  },
  data() {
    return {
      partnerStatsEvaluation: {}
    }
  },
  computed: {
    ...mapState(['estabelecimento'])
  },
  methods: {
    loadPartnerStatsEvaluation() {}
  },
  async created() {
    try {
      const response = await fetchPartnerStatsEvaluation(
        this.$store.getters.getEstabelecimento.id
      )
      if (response.data) {
        this.partnerStatsEvaluation = response.data
      }
    } catch (err) {
      //
    }
  },
  mounted(){
    this.$gtag.event('open_review_page')
  }
}
</script>

<style>
p,
b {
  color: #4e4b4e;
}

.text-header {
  font-size: 20px;
}
.text-header-2 {
  font-size: 38px;
}
.text-description {
  font-size: 16px;
}

.evaluation-card {
  padding: 16px;
}

@media screen and (max-width: 600px) {
  .evaluation-card {
    flex-direction: column;
  }

  .evaluation-card > div {
    align-self: flex-start;
  }
  .card-evaluation-measures div {
    align-self: center;
  }
  .orders-count {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .col {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
}

.card-evaluation-measures .card-evaluation {
  padding: 24px;
}

.sections-title {
  font-size: 1.4rem;
}

.topsaver {
  position: absolute !important;
  top: -1px;
  transform: translateY(-50%);
}
</style>
