<template>
  <nav>
    <div
      id="lateral-container-collapsed"
      class="box-line lateral-container-section"
      :class="[showMenuContent == true ? 'hidden-menu' : 'shown-menu']"
    >
      <div class="menu-item mt-12">
        <v-icon
          @click="$router.push({ name: 'main-board' })"
          :class="[isHomePage == true ? 'selected-item' : 'menu-text']"
          size="32"
        >
          mdi-home-outline
        </v-icon>
        <v-icon size="42" @click="showMenu" class="menu-collapse">
          mdi-chevron-right
        </v-icon>
      </div>
      <div class="menu-item mt-3">
        <v-icon
          @click="$router.push({ name: 'orders-report' })"
          :class="[isOrderResumePage == true ? 'selected-item' : 'menu-text']"
          size="32"
        >
          mdi-list-box-outline
        </v-icon>
      </div>
      <div class="menu-item mt-3">
        <v-icon
          @click="$router.push({ name: 'partner-evaluation' })"
          :class="[
            isPartnerEvaluationPage == true ? 'selected-item' : 'menu-text'
          ]"
          size="32"
        >
          mdi-star-outline
        </v-icon>
      </div>
    </div>

    <div
      class="lateral-container-content box-line lateral-container-section"
      :class="[showMenuContent == false ? 'hidden-menu' : 'shown-menu']"
    >
      <div
        class="menu-section d-flex flex-column"
        style="justify-content: space-between; height: 100%"
      >
        <div class="menu-items">
          <div class="menu-item mt-12" style="justify-content: flex-start">
            <div style="display: flex; align-items: center">
              <v-icon
                @click="$router.push({ name: 'main-board' })"
                :class="[
                  isHomePage == true ? 'selected-item' : 'menu-text',
                  'mr-2'
                ]"
                class="mr-2"
                size="32"
              >
                mdi-home-outline
              </v-icon>
              <p
                @click="$router.push({ name: 'main-board' })"
                :class="[
                  isHomePage == true ? 'selected-item ma-0' : 'menu-text ma-0'
                ]"
              >
                Ínicio
              </p>
            </div>

            <v-icon
              v-if="screenSize != 'sm' && screenSize != 'xs'"
              size="42"
              @click="showMenu"
              class="menu-collapse"
            >
              mdi-chevron-left
            </v-icon>
          </div>
          <div class="menu-item mt-3">
            <div class="d-flex align-center">
              <p class="ma-0"></p>
              <v-icon
                @click="$router.push({ name: 'orders-report' })"
                :class="[
                  isOrderResumePage == true ? 'selected-item' : 'menu-text',
                  'mr-2'
                ]"
                class="mr-2"
                size="32"
              >
                mdi-list-box-outline
              </v-icon>
              <p
                :class="[
                  isOrderResumePage == true
                    ? 'selected-item ma-0'
                    : 'menu-text ma-0',
                  'mr-2'
                ]"
                @click="$router.push({ name: 'orders-report' })"
              >
                Relatório de pedidos
              </p>
            </div>
          </div>
          <div class="menu-item mt-3">
            <div class="d-flex align-center">
              <p class="ma-0"></p>
              <v-icon
                @click="$router.push({ name: 'partner-evaluation' })"
                :class="[
                  isPartnerEvaluationPage == true
                    ? 'selected-item'
                    : 'menu-text',
                  'mr-2'
                ]"
                class="mr-2"
                size="32"
              >
                mdi-star-outline
              </v-icon>
              <p
                :class="[
                  isPartnerEvaluationPage == true
                    ? 'selected-item ma-0'
                    : 'menu-text ma-0',
                  'mr-2'
                ]"
                @click="$router.push({ name: 'partner-evaluation' })"
              >
                Avaliações
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="screenSize == 'sm' || screenSize == 'xs'"
          class="mobile-menu-items mb-8"
        >
          <div class="menu-item mt-12 justify-start">
            <div class="d-flex align-center">
              <v-icon
                @click="$emit('openChangeHourPopup')"
                class="menu-text mr-2"
                size="32"
              >
                mdi-alarm
              </v-icon>
              <p @click="$emit('openChangeHourPopup')" class="menu-text ma-0">
                Horário de retirada
              </p>
            </div>
          </div>
          <div class="menu-item mt-3">
            <div class="d-flex align-center">
              <p class="ma-0"></p>
              <v-icon @click="$emit('logout')" class="mr-2" size="32">
                mdi-logout
              </v-icon>
              <p class="menu-text ma-0" @click="$emit('logout')">Sair</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showMenuContent: false,
      screenSize: this.$vuetify.breakpoint.name
    }
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'main-board'
    },
    isOrderResumePage() {
      return this.$route.name === 'orders-report'
    },
    isPartnerEvaluationPage() {
      return this.$route.name === 'partner-evaluation'
    }
  },
  methods: {
    showMenu() {
      this.showMenuContent = !this.showMenuContent
      if (this.showMenuContent) {
        this.$gtag.event('open_menu')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './SidebarMenu.scss';
</style>
